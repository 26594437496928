export const GUEST_COVERAGE = 'GUEST_COVERAGE';
export const FETCHING_COVERAGE = 'FETCHING_COVERAGE';
export const GUEST_USER_DATA = 'GUEST_USER_DATA';
export const USER_STATE = 'USER_STATE';
export const SET_CHECKOUT_CART_DATA = 'SET_CHECKOUT_CART_DATA';
export const UPDATE_CHECKOUT_CART_DATA = 'UPDATE_CHECKOUT_CART_DATA';
export const REQUEST_CHECKOUT_CART_DATA = 'REQUEST_CHECKOUT_CART_DATA';
export const REQUEST_CASHONDELIVERY_DATA = 'REQUEST_CASHONDELIVERY_DATA';

export const CHECKOUT_URL = '/';

export const AUTH_URL = '/auth';

export const AUTH_PAGE_MOUNT = 'AUTH_PAGE_MOUNT';
export const GUEST_CHECKOUT_CLICK = 'GUEST_CHECKOUT_CLICK';
export const RETURNING_CUSTOMER_CLICK = 'RETURNING_CUSTOMER_CLICK';
export const FORGOT_PASSWORD_MOUNT = 'FORGOT_PASSWORD_MOUNT';
export const FORGOT_PASSWORD_CONFIRMATION_MOUNT = 'FORGOT_PASSWORD_CONFIRMATION_MOUNT';
export const CHECKOUT_DELIVERY_MOUNT = 'CHECKOUT_DELIVERY_MOUNT';
export const PAYMENT_GATE_MOUNT = 'PAYMENT_GATE_MOUNT';

export const LOGIN_URL = '/auth/login';
export const REGISTER_URL = '/auth/register';

export const FORGOT_PASSWORD_URL = '/auth/forgotPassword';
export const FORGOT_PASSWORD_CONFIRMATION_URL = '/auth/forgotPassword/confirmation';

export const GUEST_URL = '/auth/guest';

export const DELIVERY_URL = '/delivery';

export const BILLING_URL = '/billing';
export const CARD_URL = '/billing/card';
export const PAYPAL_URL = '/billing/paypal';
export const OXXOPAY_URL = '/billing/oxxopay';
export const CASHONDELIVERY_URL = '/billing/cashondelivery';
export const CASHONDELIVERY_DISPLAY_URL = '/checkout/payment/cashondelivery';

export const APLAZO_URL = '/billing/aplazo';

export const REVIEW_URL = '/review';

export const SCROLL_TO_ADDRESS_FORM_DURATION = 500;

export const PREDEFINED_ADDRESS = 'PredefinedAddressZoneDeliveryModeModel';

export const IS_PLACING_ORDER = 'IS_PLACING_ORDER';
export const ORDER_PLACED = 'ORDER_PLACED';

export const DISPLAY_REGISTER_BANNER = 'DISPLAY_REGISTER_BANNER';
export const SHOW_HIDE_MINIMUN_PRICE_BADGE = 'SHOW_HIDE_MINIMUN_PRICE_BADGE';

export const IS_DIRECT_DELIVERY = 'IS_DIRECT_DELIVERY';
