export const mediaQueries = {
  large: 'only screen and (min-width: 64.0625rem)',
  'large-only': 'only screen and (min-width:64.0625rem) and (max-width:80rem)',
  medium: 'only screen and (min-width:40.0625rem)',
  'medium-only': 'only screen and (min-width:40.0625rem) and (max-width:64rem)',
  'small-only': 'only screen and (max-width: 40rem)',
  topbar: 'only screen and (min-width:64.0625rem)',
  xlarge: 'only screen and (min-width:80.0625rem)',
  'xlarge-only': 'only screen and (min-width:80.0625rem) and (max-width:120rem)',
  xxlarge: 'only screen and (min-width:120.0625rem)'
};

const utils = {
  is_small_only: () => window.matchMedia(mediaQueries['small-only']).matches,
  is_medium_only: () => window.matchMedia(mediaQueries['medium-only']).matches,
  is_medium_up: () => window.matchMedia(mediaQueries.medium).matches,
  is_large_only: () => window.matchMedia(mediaQueries['large-only']).matches,
  is_large_up: () => window.matchMedia(mediaQueries.large).matches,
  is_xlarge_only: () => window.matchMedia(mediaQueries['xlarge-only']).matches,
  is_xlarge_up: () => window.matchMedia(mediaQueries.xlarge).matches,
  is_xxlarge_up: () => window.matchMedia(mediaQueries.xxlarge).matches
};

const EM_NUMBER_OF_PIXEL = parseFloat(getComputedStyle(document.querySelector('body')).fontSize);

export const getMinSizeByBreakpoint = (breakpointName) => {
  const mediaQuery = mediaQueries[breakpointName];
  const re = /.*min-width:(.*)rem.*/g;

  let result = 0;

  if (re.test(mediaQuery)) {
    result = mediaQuery.replace(re, '$1') * EM_NUMBER_OF_PIXEL;
  }

  return result;
};

export default utils;
