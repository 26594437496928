/* eslint-disable */
const {
  userTypeShortCookieName, userTypeShortCookieMaxAge, userTypeShortCookiePath, userTypeLongCookieName
} = window.inlineGlobalConfig;

const setCookie = (name, value, seconds, path) => {
  const currentDate = new Date();
  const miliseconds = 1000;
  currentDate.setTime(currentDate.getTime() + (seconds * miliseconds));
  const expires = `expires='${currentDate.toGMTString()}`;
  document.cookie = `${name}=${value};${expires};path=${path}`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const userTypeShortCookieValue = getCookie(userTypeShortCookieName);

if (userTypeShortCookieValue) {
  setCookie(userTypeShortCookieName, userTypeShortCookieValue, userTypeShortCookieMaxAge, userTypeShortCookiePath);
} else {
  const userTypeLongCookieValue = getCookie(userTypeLongCookieName);
  if (userTypeLongCookieValue) {
    setCookie(userTypeShortCookieName, userTypeLongCookieValue, userTypeShortCookieMaxAge, userTypeShortCookiePath);
  } else {
    setCookie(userTypeShortCookieName, 'prospect', userTypeShortCookieMaxAge, userTypeShortCookiePath);
  }
}

