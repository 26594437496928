import { getElementHeight } from './helpers';

const getNextElementData = (element) => {
  const nextElement = element.nextElementSibling;
  const computedStyleOfNextEl = getComputedStyle(nextElement);
  const { paddingTop, paddingBottom } = computedStyleOfNextEl;
  const nextElementHeight = parseInt(getElementHeight(nextElement), 10) + parseInt(paddingTop.replace(/\D/g, ''), 10) + parseInt(paddingBottom.replace(/\D/g, ''), 10);
  return {
    nextElement,
    paddingTop,
    paddingBottom,
    nextElementHeight
  };
};

const openAccordion = (currentElement) => {
  const {
    nextElement,
    paddingTop,
    paddingBottom,
    nextElementHeight
  } = getNextElementData(currentElement);
  currentElement.setAttribute('aria-expanded', true);
  nextElement.classList.add('slide-down');
  setTimeout(() => {
    nextElement.style.maxHeight = `${nextElementHeight}px`;
    nextElement.style.paddingTop = paddingTop;
    nextElement.style.paddingBottom = paddingBottom;
    nextElement.setAttribute('aria-hidden', 'false');
    nextElement.parentElement.classList.add('active');
  }, 0);
};

const closeAccordion = (currentElement) => {
  const nextElement = currentElement.nextElementSibling;
  nextElement.classList.remove('slide-down');
  nextElement.classList.add('slide-up');
  setTimeout(() => {
    nextElement.style.removeProperty('max-height');
    nextElement.style.paddingTop = 0;
    nextElement.style.paddingBottom = 0;
    currentElement.setAttribute('aria-expanded', 'false');
    nextElement.setAttribute('aria-hidden', 'true');
    nextElement.parentElement.classList.remove('active');
  }, 0);
};

const handleAccordionAnimation = (currentElement) => (
  new Promise(resolve => {
    const nextElement = currentElement.nextElementSibling;
    const onTransitionend = () => {
      if (nextElement.classList.contains('slide-up')) {
        nextElement.classList.remove('slide-down');
        nextElement.classList.remove('slide-up');
      }
      nextElement.removeEventListener('transitionend', onTransitionend);
      resolve();
    };
    nextElement.addEventListener('transitionend', onTransitionend);
    if (currentElement.getAttribute('aria-expanded') === 'false') {
      openAccordion(currentElement);
    } else {
      closeAccordion(currentElement);
    }
  })
);

const setElementToDefaultState = (element, callback) => {
  const nextElement = element.nextElementSibling;
  element.setAttribute('aria-expanded', 'false');
  element.removeEventListener('click', callback);
  element.parentElement.classList.remove('active');
  nextElement.setAttribute('aria-hidden', 'true');
  nextElement.style.removeProperty('max-height');
  nextElement.style.removeProperty('padding-top');
  nextElement.style.removeProperty('padding-bottom');
  nextElement.style.removeProperty('display');
  nextElement.classList.remove('slide-down');
  nextElement.classList.remove('slide-up');
};

export const accordionTransitionEffect = (elements) => {
  const onAccordionClickCallbacks = [];
  [].forEach.call(elements, (element) => {
    const nextElement = element.nextElementSibling;
    let isElementInAnimation = false;
    onAccordionClickCallbacks.push((e) => {
      e.preventDefault();
      const currentElement = e.currentTarget;
      if (!isElementInAnimation) {
        isElementInAnimation = true;
        handleAccordionAnimation(currentElement).then(() => {
          isElementInAnimation = false;
        });
      }
    });
    nextElement.style.paddingTop = 0;
    nextElement.style.paddingBottom = 0;
    nextElement.style.display = 'block';
  });
  return {
    add: () => {
      [].forEach.call(elements, (element, index) => {
        element.addEventListener('click', onAccordionClickCallbacks[index]);
      });
    },
    remove: () => {
      [].forEach.call(elements, (element, index) => {
        setElementToDefaultState(element, onAccordionClickCallbacks[index]);
      });
    }
  };
};

export default accordionTransitionEffect;
