import mediaQueries from 'app/utils/mediaQueries';
import { isEmpty, isArray } from 'lodash';
import { CART_MESSAGES_STORAGE, TYPE_WARNING, SESSION_STORAGE } from 'app/globalMessages/globalMessagesConstants';
import { saveStorageData } from 'app/localStorage/localStorage';

export const isElementVisible = (elem) => elem.offsetWidth > 0 || elem.offsetHeight > 0 || elem.getClientRects().length > 0;


export function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  return formatter.format(amount);
}

export const fadeIn = (fadeElement, speed, display) => {
  const element = fadeElement;
  element.style.display = display || 'block';
  element.classList.add(`fade-in-${speed}`);
  setTimeout(() => {
    element.classList.remove(`fade-in-${speed}`);
  }, speed);
};

export const fadeOut = (fadeElement, speed, callback) => {
  const element = fadeElement;
  element.classList.add(`fade-out-${speed}`);
  setTimeout(() => {
    element.style.display = 'none';
    element.classList.remove(`fade-out-${speed}`);
    return callback || true;
  }, speed);
};

export const getElementHeight = (element) => {
  if (!element) {
    return 0;
  }
  const elementStyle = element.getAttribute('style');
  element.setAttribute('style', 'display: block; max-height: none;');
  const height = element.offsetHeight;
  element.setAttribute('style', elementStyle);
  return height;
};

export function getElementAbsoluteHeights(elements) {
  const elementHeights = [];
  [].forEach.call(elements, (element, i) => {
    element.classList.add('temporary-hide-element');
    const paddingTop = parseInt(window.getComputedStyle(element, null).getPropertyValue('padding-top'), 10);
    const paddingBottom = parseInt(window.getComputedStyle(element, null).getPropertyValue('padding-bottom'), 10);
    const elementHeight = element.clientHeight;
    elementHeights[i] = elementHeight - paddingTop - paddingBottom;
    element.classList.remove('temporary-hide-element');
  });
  return elementHeights;
}

export function topWrapperOffset(action, offset, className) {
  const html = document.querySelector('html');
  const wrapper = document.querySelector('#wrapper');
  if (action === 'open') {
    html.classList.add(className);
    wrapper.style.marginTop = `-${offset}px`;
  } else if (action === 'close') {
    html.classList.remove(className);
    wrapper.style.removeProperty('margin-top');
    window.scrollTo(0, offset);
  }
}

export function scrollToPosition(yPos, scrollDuration) {
  return new Promise((resolve) => {
    const distance = yPos - window.scrollY;
    const currentPosition = window.scrollY;
    const denominator = 2;
    const cosParameter = distance / denominator;
    let scrollCount = 0;
    let oldTimestamp = performance.now();
    const step = (newTimestamp) => {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
      if (scrollCount >= Math.PI || window.scrollY === yPos) {
        window.scrollTo(0, yPos);
        resolve();
        return;
      }
      window.scrollTo(0, currentPosition + (distance - Math.round(cosParameter + cosParameter * Math.cos(scrollCount))));
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    };
    window.requestAnimationFrame(step);
  });
}

export const getElementPosition = (element) => {
  const bodyRect = document.body.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  return elementRect.top - bodyRect.top;
};

export const getElementHeightNoPadding = (element) => {
  if (element) {
    const computedStyle = getComputedStyle(element);
    return element.clientHeight - parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
  }
  return 0;
};

export function hideEmptyElement(element) {
  if (element.innerHTML.trim() === '') {
    element.classList.add('display-none');
  }
}

export const getParams = query => {
  if (!query) {
    return { };
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const paramsCopy = { ...params };
      const [key, value] = param.split('=');
      paramsCopy[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return paramsCopy;
    }, { });
};

export const usernamePadding = () => {
  const naveAccountWelcomeEl = document.querySelector('.nav__account-welcome');
  if (naveAccountWelcomeEl) {
    if (mediaQueries.is_xlarge_up()) {
      const navBtnLabelBoxEl = document.querySelector('.nav-btn__label-box-2');
      const width = navBtnLabelBoxEl && navBtnLabelBoxEl.offsetWidth;
      const bonusWidth = 10;
      naveAccountWelcomeEl.style.paddingRight = `${width + bonusWidth}px`;
    } else {
      naveAccountWelcomeEl.style.removeProperty('padding-right');
    }
  }
};

export const findAncestorBySelector = (element, selector) => {
  let el = element.parentElement;

  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
  }

  if (typeof el.closest === 'function') {
    return el.closest(selector) || null;
  }

  while (el) {
    if (el.matches(selector)) {
      return el;
    }
    el = el.parentElement;
  }

  return null;
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function debounceInner(...args) {
    const context = this;
    const argsCopy = args;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, argsCopy);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, argsCopy);
    }
  };
}

export const detectIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie !== -1) {
    return true;
  }
  const trident = ua.indexOf('Trident/');
  return trident !== -1;
};

export const iterateThroughObject = (object, callback) => {
  if (!isEmpty(object)) {
    /* eslint-disable */
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        callback(key, object[key]);
      }
    }
    /* eslint-enable */
  }
};

export const isJsonString = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);
    return o && typeof o === 'object' && o !== null;
  } catch (e) {
    return false;
  }
};

const ajaxFormQueryParams = (data) => {
  const bracketsRegEx = /\[\]$/;
  const query = [];

  const add = (key, value) => {
    let val;
    if (typeof value === 'function') {
      val = value();
    } else if (value === null || value === undefined) {
      val = '';
    } else {
      val = value;
    }
    query[query.length] = `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  };

  const build = (prefix, obj) => {
    if (prefix) {
      if (isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          if (bracketsRegEx.test(prefix)) {
            add(prefix, obj[i]);
          } else {
            build(`${prefix}[${(typeof obj[i] === 'object' ? i : '')}]`, obj[i]);
          }
        }
      } else if (obj && String(obj) === '[object Object]') {
        /* eslint-disable */
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            build(`${prefix}[${key}]`, obj[key]);
          }
        }
        /* eslint-enable */
      } else {
        add(prefix, obj);
      }
    } else if (isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      /* eslint-disable */
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          build(key, obj[key]);
        }
      }
      /* eslint-enable */
    }

    return query;
  };

  return build('', data).join('&').replace(/%20/g, '+');
};

const shouldAddTimestamp = (url) => !(url === window.inlineGlobalConfig.baseStoreUrl || url === window.inlineGlobalConfig.messagesUrl || url === window.inlineCartConfiguration.emptyCartUrl);

export const sessionExpiredRedirect = (url, messageId) => {
  saveStorageData(CART_MESSAGES_STORAGE, {
    validate: false,
    [TYPE_WARNING]: messageId
  }, SESSION_STORAGE);
  window.location.href = url;
};

export const ajaxFetchRequest = (method, url, formData, requestHeaders = {
  'Content-type': 'application/x-www-form-urlencoded',
  'X-Requested-With': 'XMLHttpRequest'
}) => (
  new Promise((resolve, reject) => {
    const params = ajaxFormQueryParams(formData);
    let requestUrl = (method === 'GET' && params) ? `${url}?${params}` : url;
    if (method === 'GET' && shouldAddTimestamp(url)) {
      requestUrl = (params || requestUrl.includes('?')) ? `${requestUrl}&t=${Date.now()}` : `${requestUrl}?t=${Date.now()}`;
    }
    fetch(requestUrl, {
      method,
      headers: requestHeaders,
      body: params
    })
      .then(response => response.text())
      .then((response) => {
        let responseFetch = null;
        if (isJsonString(response)) {
          responseFetch = JSON.parse(response);
        } else if ((response.indexOf('\'') !== -1) && (response.indexOf('[') !== -1) && (response.indexOf(']') !== -1)) {
          responseFetch = response.replaceAll('\'', '"');
          responseFetch = responseFetch.replaceAll('[', '{');
          responseFetch = responseFetch.replaceAll(']', '}');
          responseFetch = JSON.parse(responseFetch);
        } else {
          responseFetch = response;
        }
        return resolve(responseFetch);
      })
      .catch(error => reject(error));
  }));


export const ajaxRequest = (method, url, formData, customErrorHandler = null, requestHeaders = {
  'Content-type': 'application/x-www-form-urlencoded',
  'X-Requested-With': 'XMLHttpRequest'
}) => (
  new Promise((resolve, reject) => {
    const xhttp = new XMLHttpRequest();
    const params = ajaxFormQueryParams(formData);
    let requestUrl = (method === 'GET' && params) ? `${url}?${params}` : url;
    if (method === 'GET' && shouldAddTimestamp(url)) {
      requestUrl = (params || requestUrl.includes('?')) ? `${requestUrl}&t=${Date.now()}` : `${requestUrl}?t=${Date.now()}`;
    }
    xhttp.open(method, requestUrl, true);
    xhttp.onload = () => {
      if (customErrorHandler && customErrorHandler.status === xhttp.status) {
        const { redirectHandler, redirectUrl, messageId } = customErrorHandler;
        return redirectHandler(redirectUrl, messageId);
      }
      return resolve(isJsonString(xhttp.responseText) ? JSON.parse(xhttp.responseText) : xhttp.responseText);
    };
    xhttp.onerror = () => reject(xhttp.statusText);
    iterateThroughObject(requestHeaders, (key, value) => xhttp.setRequestHeader(key, value));
    xhttp.send(method === 'GET' ? undefined : params);
  }));


export const simpleAjaxRequest = (method, url, formData, customErrorHandler = null) => (
  new Promise((resolve, reject) => {
    const xhttp = new XMLHttpRequest();
    const params = ajaxFormQueryParams(formData);
    let requestUrl = (method === 'GET' && params) ? `${url}?${params}` : url;
    if (method === 'GET') {
      requestUrl = (params || requestUrl.includes('?')) ? `${requestUrl}&t=${Date.now()}` : `${requestUrl}?t=${Date.now()}`;
    }
    xhttp.open(method, requestUrl, true);
    xhttp.onload = () => {
      if (customErrorHandler && customErrorHandler.status === xhttp.status) {
        const { redirectHandler, redirectUrl, messageId } = customErrorHandler;
        return redirectHandler(redirectUrl, messageId);
      }
      return resolve(isJsonString(xhttp.responseText) ? JSON.parse(xhttp.responseText) : xhttp.responseText);
    };
    xhttp.onerror = () => reject(xhttp.statusText);
    xhttp.send(method === 'GET' ? undefined : params);
  }));


export const decodeHtml = (html) => {
  const txt = document.createElement('textarea');
  const slashUnicode = '&#92;u002f';
  txt.innerHTML = html.replace(slashUnicode, '/');
  return txt.value;
};

export const getElementIndex = el => Array.prototype.indexOf.call(el.parentNode.children, el);

export const parseUndefinedAndNull = (a) => {
  try {
    if (a === null || a === undefined) {
      return null;
    }
    return a;
  } catch (e) {
    return null;
  }
};
