import React from 'react';
import { FormattedMessage } from 'react-intl';

export const RESTORE_ATB_SECONDS = 3000;
export const RESTORE_MODAL_ADDTOCART = 5000;

export const REQUEST_ADD_CART_ITEM = 'REQUEST_ADD_CART_ITEM';
export const RECEIVE_ADD_CART_ITEM = 'RECEIVE_ADD_CART_ITEM';

export const ADD_TO_BAG_SUCCESS_MESSAGE_ID = 'products.cta.addToBag.addedToBagMsg';
export const OUT_OF_STOCK_ERROR_MESSAGE_ID = 'products.cta.addToBag.outOfStockError';

// QTY Form Constants
export const UPDATE_ADD_TO_CART_QTY_FORM = 'UPDATE_ADD_TO_CART_QTY_FORM';
export const MINIMUM_QTY = 1;
export const MINIMUM_QTY_REACHED = 'MINIMUM_QTY_REACHED';
export const MAXIMUM_QTY = 10;
export const MAXIMUM_QTY_REACHED = 'MAXIMUM_QTY_REACHED';

export const STATUS_CODES = {
  ADD_TO_BAG_DISABLED: 'ADD_TO_BAG_DISABLED',
  ADD_TO_BAG_SELECT_SIZE: 'ADD_TO_BAG_SELECT_SIZE',
  ADD_TO_BAG_READY: 'ADD_TO_BAG_READY',
  ADDING_TO_BAG: 'ADDING_TO_BAG',
  ADDED_TO_BAG: 'ADDED_TO_BAG',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  MAX_QTY_IN_CART_REACHED: 'MAX_QTY_IN_CART_REACHED',
  ERROR: 'ERROR',
  BUY_NOW: 'BUY_NOW',
  BUYING_NOW: 'BUYING_NOW',
  BUY_NOW_READY: 'BUY_NOW_READY'
};

export const DEFAULT_QTY_FORM_STATE = {
  actualQty: 1,
  minimumQtyReached: true,
  maximumQtyReached: false,
  actualProductSKU: '',
  addToCartMax: 10
};

export const ATB_STATE = {};

ATB_STATE[STATUS_CODES.ADD_TO_BAG_SELECT_SIZE] = {
  code: STATUS_CODES.ADD_TO_BAG_SELECT_SIZE,
  classNameATB: '_is-invalid _is-hover-action-enabled',
  icon: '_is-hidden',
  buttonText: (<FormattedMessage id="products.cta.addToBag.addToBag" />),
  hoverText: (<FormattedMessage id="products.cta.addToBag.addToBagSelectSize" />),
  disabled: false,
};

ATB_STATE[STATUS_CODES.ADD_TO_BAG_READY] = {
  code: STATUS_CODES.ADD_TO_BAG_READY,
  classNameATB: '_is-add-to-bag-ready',
  icon: 'icon-cart',
  buttonText: (<FormattedMessage id="products.cta.addToBag.addToBag" />),
  disabled: false,
};

ATB_STATE[STATUS_CODES.ADDING_TO_BAG] = {
  code: STATUS_CODES.ADDING_TO_BAG,
  classNameATB: '_is-adding-to-bag',
  icon: 'icon-loop',
  buttonText: (<FormattedMessage id="products.cta.addToBag.addingToBag" />),
  disabled: true,
};

ATB_STATE[STATUS_CODES.ADDED_TO_BAG] = {
  code: STATUS_CODES.ADDED_TO_BAG,
  classNameATB: '_is-added-to-bag',
  icon: 'icon-tick-right',
  buttonText: (<FormattedMessage id="products.cta.addToBag.addedToBag" />),
  disabled: true,
};

ATB_STATE[STATUS_CODES.ERROR] = {
  code: STATUS_CODES.ERROR,
  classNameATB: '_is-add-to-bag-error',
  icon: '_is-hidden',
  buttonText: (<FormattedMessage id="products.cta.addToBag.addToBag" />),
  disabled: true,
};

ATB_STATE[STATUS_CODES.OUT_OF_STOCK] = {
  code: STATUS_CODES.OUT_OF_STOCK,
  classNameATB: '_is-out-of-stock',
  icon: '_is-hidden',
  buttonText: (<FormattedMessage id="products.cta.addToBag.addToBag" />),
  hoverText: '',
  disabled: true,
  errorText: (<FormattedMessage id="products.cta.addToBag.outOfStockError" />)
};

ATB_STATE[STATUS_CODES.MAX_QTY_IN_CART_REACHED] = {
  code: STATUS_CODES.MAX_QTY_IN_CART_REACHED,
  classNameATB: '_is-max-qty-cart-reached',
  icon: '_is-hidden',
  buttonText: (<FormattedMessage id="products.cta.addToBag.addToBag" />),
  hoverText: '',
  disabled: true,
  errorText: (<FormattedMessage id="products.cta.addToBag.maxQtyReached" />)
};

ATB_STATE[STATUS_CODES.ADD_TO_BAG_DISABLED] = {
  code: STATUS_CODES.ADD_TO_BAG_DISABLED,
  classNameATB: '_is-disabled',
  icon: '_is-hidden',
  buttonText: (<FormattedMessage id="products.cta.addToBag.addToBag" />),
  hoverText: '',
  disabled: true,
};

ATB_STATE[STATUS_CODES.BUY_NOW] = {
  code: STATUS_CODES.BUY_NOW,
  classNameATB: '_is-buy-now',
  icon: '_is-hidden',
  buttonText: (<FormattedMessage id="products.cta.buyNow" />),
  hoverText: '',
  disabled: true,
};

ATB_STATE[STATUS_CODES.BUYING_NOW] = {
  code: STATUS_CODES.BUYING_NOW,
  classNameATB: '_is-buying-now',
  icon: 'icon-loop',
  buttonText: (<FormattedMessage id="products.cta.buyingNow" />),
  disabled: true,
};

ATB_STATE[STATUS_CODES.BUY_NOW_READY] = {
  code: STATUS_CODES.BUY_NOW_READY,
  classNameATB: '_is-buy_now-ready',
  icon: 'icon-pago-c-entrega',
  buttonText: (<FormattedMessage id="products.cta.buyNow" />),
  disabled: false,
};
