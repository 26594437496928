import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PROMO_ERROR_MSG = 'order.summary.promo.error.msg';
export const NOPROMO_ERROR_MSG = 'order.summary.nopromo.error.msg';
export const BTN_INPUT_PROMO = 'order.summary.promo.apply.cta';
export const PROMO_APPLIED_MSG = 'order.summary.promo.applied';

export const PROMO_STATUSES = {
  NO_PROMO_APPLIED: 'NO_PROMO_APPLIED',
  PROMO_INPUT_BOX: 'PROMO_INPUT_BOX',
  PROMO_INPUT_BOX_PROGRESS: 'PROMO_INPUT_BOX_PROGRESS',
  INVALID_PROMO: 'INVALID_PROMO',
  PROMO_APPLIED: 'PROMO_APPLIED',
};

export const PROMO_STATE = {};

PROMO_STATE[PROMO_STATUSES.NO_PROMO_APPLIED] = {
  code: PROMO_STATUSES.NO_PROMO_APPLIED,
  showPromoInput: false
};

PROMO_STATE[PROMO_STATUSES.PROMO_INPUT_BOX] = {
  code: PROMO_STATUSES.PROMO_INPUT_BOX,
  showPromoInput: true,
  buttonText: (<FormattedMessage id="order.summary.promo.apply.cta" />),
  IsCtaDisabled: false,
  IsInputBoxDisabled: false,
  className: 'cart-order-summary__promo-input'
};

PROMO_STATE[PROMO_STATUSES.PROMO_INPUT_BOX_PROGRESS] = {
  code: PROMO_STATUSES.PROMO_INPUT_BOX_PROGRESS,
  showPromoInput: true,
  buttonText: (<FormattedMessage id="order.summary.promo.applying.cta" />),
  IsCtaDisabled: true,
  IsInputBoxDisabled: true,
  className: 'cart-order-summary__promo-input '
};

PROMO_STATE[PROMO_STATUSES.INVALID_PROMO] = {
  code: PROMO_STATUSES.INVALID_PROMO,
  buttonText: (<FormattedMessage id="order.summary.promo.apply.cta" />),
  IsCtaDisabled: true,
  IsInputBoxDisabled: false,
  className: 'cart-order-summary__promo-input _is-error'
};

PROMO_STATE[PROMO_STATUSES.PROMO_APPLIED] = {
  code: PROMO_STATUSES.PROMO_APPLIED,
  showPromoInput: false
};
