/* eslint-disable */
import { omit } from 'lodash';

const {
  locale,
  pageName,
  pageType,
  gaTrackingId
} = window.inlineGlobalConfig;

export const getProductName = (name) => (name.replace('<sup>&trade;</sup>', ''));

export const getProductAge = (nrOfDays) => {
  const productAgeTypes = [
    {
      value: 14,
      content: '2 weeks'
    },
    {
      value: 90,
      content: '3 months',
    },
    {
      value: 182,
      content: '6 months',
    },
    {
      value: 365,
      content: 'year',
    }
  ];
  const productAgeTypesLength = productAgeTypes.length;
  if (productAgeTypes[0].value > nrOfDays) {
    return `past ${productAgeTypes[0].content}`;
  }
  for (let i = 1; i < productAgeTypesLength; i++) {
    if (productAgeTypes[i - 1].value <= nrOfDays && nrOfDays < productAgeTypes[i].value) {
      return `past ${productAgeTypes[i].content}`;
    }
  }
  return 'past more than one year';
};

export const getLocaleDataByUid = (uid) => {
  switch (uid) {
    case 'mx': return {
      country: 'mexico',
      gaAccount: gaTrackingId,
      currency: '$'
    };
    default: return {
      country: 'mexico',
      gaAccount: gaTrackingId,
      currency: '$'
    };
  }
};

export const getLanguageName = (language) => {
  switch (language) {
    case 'es': return 'spanish';
    default: return 'spanish';
  }
};

export const getProductAvailability = (sizes) => {
  const maxPercentage = 100;
  let numberOfNoStockSizes = 0;
  sizes.forEach((size) => {
    if (size.count <= 0) {
      numberOfNoStockSizes++;
    }
  });
  return Math.round(maxPercentage - (numberOfNoStockSizes * maxPercentage) / sizes.length);
};

export const getExtendedProductData = (product) => {
  let productName = '';
  let productSKU = '';
  let productPrice = '0.0';
  let productPriceOffer = '0.0';

  if (product !== undefined) {
    if (product.product !== undefined) {
      productSKU = product.product.baseProduct;
      productName = product.product.name;
      productPrice = product.product.price.value;
      productPriceOffer = product.product.offerPrice;
    } else {
      if (product.id !== undefined) {
        productSKU = product.id;
      } else if (product.productCode !== undefined) {
        productSKU = product.productCode;
      }
      productName = product.name;
      /* eslint-disable */
      productPrice = (typeof(product.price) === 'object') ? product.price.value : product.price;
      productPriceOffer = (product.offerPrice !== undefined) ? product.offerPrice : '0.0';
    }
  }
  /* eslint-enable */
  const productData = {
    productSKU,
    productName,
    productPrice,
    productPriceOffer
  };
  return productData;
};

export const parseToGTMFloat = (number) => (parseFloat(number).toFixed(2)); // eslint-disable-line

export const getProductData = (product) => {
  const extendedProductData = getExtendedProductData(product);
  const productData = {
    ...Object.assign(extendedProductData)
  };

  /* eslint-disable */
  return productData;
};

export const getCommonDataLayerInfo = (accountData) => {
  const { accountIsLogged, encodedId } = accountData;
  const dataLayerInfo = {
    ga_account: getLocaleDataByUid(locale.country).gaAccount,
    page_name: `/ga-virtual${pageName.toLowerCase()}`,
    page_type: pageType.toLowerCase().replace(new RegExp('\\-|_', 'g'), ' '),
    site: getLocaleDataByUid(locale.country).country,
    language: getLanguageName(locale.language),
    user_status: accountIsLogged ? 'logged in' : 'not logged in'
  };
  if (accountIsLogged) {
    dataLayerInfo.customer_id = encodedId;
  }
  return dataLayerInfo;
};

export const isCartIdLoaded = (accountData) =>
  new Promise((resolve) => {
    const interval = 500;
    const intervalID = setInterval(() => {
      if (accountData.bag_id !== null) {
        clearInterval(intervalID);
        resolve();
      }
    }, interval);
  });

export const isDataLoaded = (accountData) =>
  new Promise((resolve) => {
    const interval = 500;
    const intervalID = setInterval(() => {
      if (accountData.accountIsLogged === null) {
        return;
      }
      clearInterval(intervalID);
      resolve();
    }, interval);
  });

export const handleFilterTracking = (isSelected = true, action, searchMetadata) => {
  try {
    window.dataLayer.push({
      event: 'visitor interaction',
      interaction_name: isSelected ? 'apply filter' : 'remove filter',
      [isSelected ? 'apply_filter_category' : 'remove_filter_category']: action.facetHeaderName ? action.facetHeaderName.toLowerCase() : '',
      [isSelected ? 'apply_filter_value' : 'remove_filter_value']: action.facetItem.id
        .replace(/C\$/g, '')
        .replace(/\$/g, '')
        .replace(/€/g, '')
        .replace(/£/g, '')
        .replace(/,/g, '.')
        .toLowerCase(),
      [isSelected ? 'apply_filter_results' : 'remove_filter_results']: searchMetadata.totalItems
    });
  } catch (error) {
    console.error(error);
  }
};

export const positionRemapping = (position) => {
  switch (position) {
    case 'media-banner__copy-top-left':
      return 'top left';
    case 'media-banner__copy-top-center':
      return 'top center';
    case 'media-banner__copy-top-right':
      return 'top right';
    case 'media-banner__copy-middle-left':
      return 'middle left';
    case 'media-banner__copy-middle-center':
      return 'middle center';
    case 'media-banner__copy-middle-right':
      return 'middle right';
    case 'media-banner__copy-bottom-left':
      return 'bottom left';
    case 'media-banner__copy-bottom-center':
      return 'bottom center';
    case 'media-banner__copy-bottom-right':
      return 'bottom right';
    default:
      return position;
  }
};

export const findParentRowComponent = (element) => {
  if (element.nodeName === 'BODY' || element.nodeName === 'HTML') {
    return '';
  } else if (element.parentNode.classList.contains('row-component')) {
    return element.parentNode.dataset.slotName;
  }
  return findParentRowComponent(element.parentNode);
};

export const replaceSpecialChars = (string = '') =>
  (string
    .replace(/_|-/g, ' ')
    .replace(/&/g, ' and ')
    .replace(/\n/ig, '')
    .replace(/[^A-Za-z0-9\s.]/g, '')
    .replace(/\s\s+/g, ' ')
    .toLowerCase()
  );

export const checkForTextExistence = (element, type) => {
  if (element && element.dataset[type]) {
    const datasetText = element.dataset[type].replace('<br>', ' ');
    const placeholder = document.createElement('div');
    placeholder.innerHTML = datasetText;
    return placeholder.innerText || placeholder.textContent;
  }
  return '';
};

export const reduceProductFields = ((productsAnalytics) =>
  productsAnalytics.map(product => omit(product, ['brand', 'variant', 'dimension1', 'dimension3', 'dimension8', 'dimension9'])));

export const getCategoryPage = function(pageType) {
  if(pageType !== 'homepage') {
    return { pageCategory:  pageName.toLowerCase().replace('/', '') };
  } else {
    return {};
  }
};


export const getCommonData = function() {
  const pageTypeData = pageType.toLowerCase().replace(new RegExp('\\-|_', 'g'), ' ');
  const dataLayerInfo = {
    url: window.location.href,
    ...Object.assign(getCategoryPage(pageTypeData)),
  };
  return dataLayerInfo;
};


export const getProductsGtm = function(products)  {
  if (products !== undefined) {
    if (products.length > 0) {
      return {products: products.map((product, index) => (Object.assign({}, getExtendedProductData(product)))) };
    }
  }
  return {};
}

export const getOrderId = function(order) {
  let orderId = `0`;

  if (order.id !== undefined) {
    orderId = order.id;
  } else if (order.code !== undefined) {
    orderId = order.code;
  }
  const productData = {
    orderId
  }
  return productData;
};

export const getCurrency = function() {
  var currency = '${session.currentCurrency}';
  const productData = {
    currency
  }
  return productData;
};

export const getPaymentMethod = function(payment) {
  var paymentMethod = ``;
  switch(payment.toUpperCase() ){
    case `PAYPAL`:
      paymentMethod = `Paypal`;
      break;
    case `CASHONDELIVERY`:
      paymentMethod = `Contra entrega`;
      break;
    case `CREDITCARD`:
      paymentMethod = `Tarjeta de Crédito o Débito`;
      break;
    default:
    paymentMethod = payment;
  }
  const productData = {
    paymentMethod
  }
  return productData;
};

export const getQuantity = function(order) {
  let quantity = `0`;
  if (order.totalItems !== undefined) {
    quantity = order.totalItems;
  } else if (order.entries !== undefined) {
    quantity = order.entries.map(o => o.quantity).reduce((a, c) => { return a + c });
  }
  const productData = {
    quantity
  }
  return productData;
};

export const fixPriceFormat = function(price) {
  let priceFormat = price.toString();
  if (priceFormat.toString().indexOf('$') === -1) {
    priceFormat = '$' + priceFormat.toString();
  } else {
    priceFormat = priceFormat.toString();
  }
  return priceFormat;
}

