export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
export const SET_IFRAME_COMPLETED = 'SET_IFRAME_COMPLETED';
export const SELECT_GIFT_OPTION = 'SELECT_GIFT_OPTION';
export const CARD_DETAILS_MOUNT = 'CARD_DETAILS_MOUNT';
export const REDIRECT_TIME = 1800;
export const RELOAD_TIME = 3000;

export const PAYMENT_METHOD = {
  CARD: 'CARD',
  PAYPAL: 'PAYPAL',
  APLAZO: 'APLAZO',
  ZEROVALUE: 'ZEROVALUE',
  OXXOPAY: 'OXXOPAY',
  CASHONDELIVERY: 'CASHONDELIVERY'
};
